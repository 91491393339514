<template>
  <v-container>
    <h2>顧客更新</h2>
    <div class="container">
      <form @submit="updateFrom">
        <div class="row">
          <v-col class="pr-2 pb-2" cols="12">
            <v-row no-gutters align="center">
              <v-col cols="12" md="4" xs="2">
                <span class="label">{{ $t("message.label-email") }}</span>
              </v-col>
              <v-col cols="12" md="5" xs="2">
                <div>
                  <v-text-field
                    v-model="form.email"
                    :error-messages="emailErrors"
                    @input="$v.form.email.$touch()"
                    @blur="$v.form.email.$touch()"
                    outlined
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pr-2 pb-2" cols="12">
            <v-row no-gutters align="center">
              <v-col cols="12" md="4" xs="2">
                <span class="label">{{ $t("message.label-name") }}</span>
              </v-col>
              <v-col cols="12" md="5" xs="2">
                <div>
                  <v-text-field
                    v-model="form.customer_name"
                    outlined
                    :error-messages="nameErrors"
                    @input="$v.form.customer_name.$touch()"
                    @blur="$v.form.customer_name.$touch()"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pr-2 pb-2" cols="12">
            <v-row no-gutters align="center">
              <v-col cols="12" md="4" xs="2">
                <span class="label">{{ $t("message.label-furigana") }}</span>
              </v-col>
              <v-col cols="12" md="5" xs="2">
                <div>
                  <v-text-field
                    v-model="form.furigana"
                    outlined
                    :error-messages="furiganaErrors"
                    @input="$v.form.furigana.$touch()"
                    @blur="$v.form.furigana.$touch()"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pr-2 pb-2" cols="12">
            <v-row no-gutters align="center">
              <v-col cols="12" md="4" xs="2">
                <span class="label">{{ $t("message.label-phone") }}</span>
              </v-col>
              <v-col cols="12" md="5" xs="2">
                <div>
                  <v-text-field
                    v-model="form.phone"
                    :error-messages="phoneErrors"
                    @input="$v.form.phone.$touch()"
                    @blur="$v.form.phone.$touch()"
                    outlined
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pr-2 pb-2" cols="12">
            <v-row no-gutters align="center">
              <v-col cols="12" md="4" xs="2">
                <span class="label">{{ $t("message.label-gender") }}</span>
              </v-col>
              <v-col cols="12" md="5" xs="2">
                <v-radio-group v-model="form.gender" row mandatory>
                  <v-radio
                    v-for="gender in selectedGender"
                    :key="gender.id"
                    :value="gender.id"
                    color="indigo"
                    :label="gender.label"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="pr-2 pb-2" cols="12">
            <v-row no-gutters align="center">
              <v-col cols="12" md="4" xs="2">
                <span class="label">{{ $t("message.label-birthday") }}</span>
              </v-col>
              <v-col cols="12" md="5" xs="2">
                <v-menu
                  v-model="modal"
                  :close-on-content-click="false"
                  :nudge-left="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.bod"
                      append-icon="mdi-calendar"
                      :error-messages="bodErrors"
                      @input="$v.form.bod.$touch()"
                      @blur="$v.form.bod.$touch()"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      color="primary"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.bod"
                    color="primary"
                    @input="modal = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pr-2 pb-2" cols="12">
            <v-row no-gutters align="center">
              <v-col cols="12" md="4" xs="2">
                <span class="label">{{ $t("message.label-status") }}</span>
              </v-col>
              <v-col cols="12" md="5" xs="2">
                <v-select
                  :items="selectedStatus"
                  item-text="label"
                  item-value="value"
                  outlined
                  v-model="form.status"
                />
              </v-col>
            </v-row>
          </v-col>
        </div>
        <div class="fixed-div">
          <v-row class="d-flex justify-space-between mb-6">
            <v-col>
              <v-btn
                rounded
                color="secondary"
                dark
                style="float:start"
                @click.prevent="goBack"
              >
                {{ $t("message.btn-back") }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                rounded
                color="primary"
                dark
                style="float:right"
                @click="updateFrom"
              >
                {{ $t("message.btn-update") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </form>
      <div>
        <v-snackbar
          v-if="is_snackbar"
          v-snackbar
          :timeout="timeout"
          :value="true"
          absolute
          top
          right
          color="indigo"
          elevation="5"
        >
          フォームは全データを正しく入力してください
        </v-snackbar>
      </div>
    </div>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "CustomerUpdate",
  mixins: [validationMixin],
  data: () => ({
    form: {
      email: "",
      customer_name: "",
      furigana: "",
      bod: null,
      phone: "",
      gender: "",
      status: null,
    },
    is_snackbar: false,
    timeout: 2000,
    modal: false,
  }),
  validations: {
    form: {
      customer_name: { required },
      furigana: { required },
      email: { required },
      phone: { required },
      bod: { required },
    },
  },
  watch: {},
  computed: {
    selectedStatus() {
      return [
        { value: 1, label: this.$t("message.label-active") },
        { value: 0, label: this.$t("message.label-inactive") },
      ];
    },
    selectedGender() {
      return [
        { id: 1, value: "male", label: this.$t("message.label-male") },
        { id: 2, value: "female", label: this.$t("message.label-female") },
      ];
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push("error email");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.form.customer_name.$dirty) return errors;
      !this.$v.form.customer_name.required && errors.push("error name");
      return errors;
    },
    furiganaErrors() {
      const errors = [];
      if (!this.$v.form.furigana.$dirty) return errors;
      !this.$v.form.furigana.required && errors.push("error furigana");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;
      !this.$v.form.phone.required && errors.push("error phone");
      return errors;
    },
    bodErrors() {
      const errors = [];
      if (!this.$v.form.bod.$dirty) return errors;
      !this.$v.form.bod.required && errors.push("error bod");
      return errors;
    },
  },
  created() {
    this.dispatchCustomerDetail();
  },
  methods: {
    async dispatchCustomerDetail() {
      let id = this.$route.params.id;
      await this.$store.dispatch("customer_module/fetchCustomerDetail", id);
      let customerDetail = this.$store.state.customer_module.customerResult;

      this.form.email = customerDetail.email;
      this.form.customer_name = customerDetail.customer_name;
      this.form.furigana = customerDetail.furigana;
      this.form.bod = customerDetail.bod;
      this.form.gender = customerDetail.gender_id;
      this.form.phone = customerDetail.phone;
      this.form.status = customerDetail.is_active;
    },
    updateFrom() {
      this.$v.$touch();
      this.is_snackbar = false;
      if (!this.$v.$invalid) {
        this.is_snackbar = false;
        let id = this.$route.params.id;
        this.$store.dispatch("customer_module/updateCustomer", {
          id: id,
          form: {
            name: this.form.customer_name,
            furigana: this.form.furigana,
            email: this.form.email,
            bod: this.form.bod,
            gender: this.form.gender,
            phone: this.form.phone,
            is_active: this.form.status,
          },
        });
      } else {
        this.is_snackbar = true;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
@media only screen and (max-width: 640px) {
  .nested-label-col-2 {
    padding-top: 0;
    padding-bottom: 0;
    width: 200vw;
    margin-left: 17px;
  }
  .nested-label-col-3 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.label {
  text-transform: capitalize;
}
.label-title {
  text-transform: capitalize;
  color: #3f51b5;
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fixed-div {
  margin-top: auto;
  bottom: 0px;
  right: 50%;
}

.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
@media only screen and (max-width: 640px) {
  .btn-search-filter-customer {
    float: none !important;
  }
  .col-mobile-width {
    width: 50% !important;
  }
}
</style>
